import React from "react"
import { graphql } from "gatsby"

import SEO from "@components/seo"
import Header from "@components/header"
import Footer from "@components/footer"
import Blocks from "@components/blocks"

import FadeLink from "@components/fade-link"

const SubPageTemplate = ({ data: { page } }) => {
  const { seo, blocks } = page
  const { sections } = blocks

  return (
    <div>
      <main className={`inner-program-wrapper`}>
        <SEO seo={seo} />
        <div className="back-to-bar">
          <div className="container">
            <div className="columns is-flex">
              <div className="column is-6 is-8-mobile">
                <FadeLink className="h6" style={{ margin: 0 }} to={`/`}>
                  <svg
                    viewBox="0 0 25 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    alt="Back Arrow"
                  >
                    <path
                      d="M7.34375 14.844C7.57812 14.6099 7.57812 14.2198 7.34375 13.9077L2.10938 8.13394H24.375C24.6875 8.13394 25 7.82185 25 7.50975C25 7.19766 24.6875 6.88557 24.375 6.88557H2.10938L7.34375 1.11183C7.57812 0.799741 7.57812 0.487647 7.34375 0.175553C7.10938 -0.0585165 6.64062 -0.0585165 6.40625 0.175553C6.40625 0.175553 0.234375 6.96359 0.15625 7.04161C0.15625 7.11964 0 7.27568 0 7.50975C0 7.74382 0.15625 7.89987 0.15625 7.97789C0.234375 8.05592 6.40625 14.844 6.40625 14.844C6.5625 14.922 6.71875 15 6.875 15C7.03125 15 7.1875 14.922 7.34375 14.844Z"
                      fill="#333132"
                    />
                  </svg>
                  {`Back to Home`}
                </FadeLink>
              </div>

              <div className="column is-6 has-text-right">
                <FadeLink to={`/`}>
                  <svg
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30 0L16 14L2 0L0 2L14 16L0 30L2 32L16 18L30 32L32 30L18 16L32 2L30 0Z"
                      fill="#333132"
                    />
                  </svg>

                  <span className="is-sr-only">{`Back to Home`}</span>
                </FadeLink>
              </div>
            </div>
          </div>
        </div>
        <Blocks blocks={sections} />
      </main>
    </div>
  )
}

export default SubPageTemplate

export const pageQuery = graphql`
  query SubPageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      id
      title
      seo {
        title
        metaDesc
        metaKeywords
        canonical
        readingTime
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphSiteName
        opengraphPublisher
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphDescription
        opengraphAuthor
        opengraphImage {
          localFile {
            publicURL
          }
        }
        twitterDescription
        twitterTitle
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
      blocks {
        sections {
          ... on WpPage_Blocks_Sections_LocationListing {
            fieldGroupName
            heading
            bodyText
            locations {
              title
              phoneNumber {
                label
                phoneNumber
              }
              address
            }
            image {
              altText
              localFile {
                publicURL
              }
            }
            links {
              anchorId
              customUrl
              externalUrl
              forceDownload
              linkText
              linkType
              pageLink {
                ... on WpPage {
                  id
                  uri
                }
                ... on WpProgram {
                  id
                  uri
                }
                ... on WpPost {
                  id
                  uri
                }
              }
              file {
                localFile {
                  publicURL
                }
              }
            }
            hideSection
            sectionSelectors {
              sectionClasses
              sectionId
            }
          }
          ... on WpPage_Blocks_Sections_ResourceListing {
            fieldGroupName
            heading
            bodyText
            resources {
              heading
              resources {
                title
                file {
                  localFile {
                    publicURL
                  }
                }
              }
            }
            hideSection
            sectionSelectors {
              sectionClasses
              sectionId
            }
          }
          ... on WpPage_Blocks_Sections_ProgramListing {
            fieldGroupName
            backgroundColor
            bodyText
            heading
            subheading
            programCategory {
              databaseId
              name
              programs {
                programs: nodes {
                  id
                  title
                  uri
                  menuOrder
                  programDetails {
                    ageGroup {
                      ageGroup
                    }
                    locations {
                      name
                    }
                    carouselImage {
                      altText
                      localFile {
                        publicURL
                      }
                    }
                  }
                }
              }
            }
            hideSection
            sectionSelectors {
              sectionClasses
              sectionId
            }
          }
          ... on WpPage_Blocks_Sections_TextColumns {
            fieldGroupName
            heading
            hideSection
            leftColumn
            rightColumn
            links {
              anchorId
              customUrl
              externalUrl
              forceDownload
              linkText
              linkType
              pageLink {
                ... on WpPage {
                  id
                  uri
                }
                ... on WpProgram {
                  id
                  uri
                }
                ... on WpPost {
                  id
                  uri
                }
              }
              file {
                localFile {
                  publicURL
                }
              }
            }
            sectionSelectors {
              sectionClasses
              sectionId
            }
          }
          ... on WpPage_Blocks_Sections_TextWithImage {
            alignment
            bodyText
            fieldGroupName
            heading
            style
            subheading
            image {
              altText
              localFile {
                publicURL
              }
            }
            links {
              anchorId
              customUrl
              externalUrl
              forceDownload
              linkText
              linkType
              pageLink {
                ... on WpPage {
                  id
                  uri
                }
                ... on WpProgram {
                  id
                  uri
                }
                ... on WpPost {
                  id
                  uri
                }
              }
              file {
                localFile {
                  publicURL
                }
              }
            }
            hideSection
            sectionSelectors {
              sectionClasses
              sectionId
            }
          }
        }
      }
    }
  }
`
